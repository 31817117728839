<template>
  <page-content>

    <common-table
        ref="table"
        path="web/machine/aftersales"
        :columns="columns"
        search-placeholder="搜索手机号"
    >

      <template slot="search">
        <a-button ghost type="primary" @click="add">新增</a-button>
      </template>

      <template slot="res" slot-scope="{record}">
        <div style="display: flex" @click.prevent="clickImage(record)">
          <video-item class="res" v-for="res in JSON.parse(record.res)" :res="res"/>
        </div>
      </template>

      <template slot="operation" slot-scope="{record}">
<!--        <action-edit @click="edit(record)"></action-edit>-->
<!--        <action-delete @click="remove(record)"></action-delete>-->

        <a-button v-if="record.rewardStatus!=2 && record.priceReward>0" size="small" @click="clickDeduction(record)">扣除奖励</a-button>

      </template>

    </common-table>

    <AfterSalesEdit ref="afterSalesEdit" @success="getList"></AfterSalesEdit>
    <AfterSalesDeduction ref="afterSalesDeduction"  @ok="didDeduction"></AfterSalesDeduction>

    <ImageModal ref="imageModal"></ImageModal>

  </page-content>
</template>

<script>

import AfterSalesEdit from './AfterSalesEdit'
import AfterSalesDeduction from "./AfterSalesDeduction";
import ImageModal from "./ImageModal"

export default {
  components: {
    AfterSalesEdit, AfterSalesDeduction, ImageModal
  },
  data() {
    return {}
  },

  computed: {
    columns() {
      return [
        {
          title: '产品名称',
          dataIndex: 'productName'
        },
        // {
        //   title: '件号',
        //   dataIndex: 'partNo'
        // },
        {
          title: '序列号',
          dataIndex: 'serialNumber'
        },
        {
          title: '提交人',
          dataIndex: 'userName'
        },
        {
          title: '提交人手机号',
          dataIndex: 'userPhone'
        },
        {
          title: '提交时间',
          dataIndex: 'createdAt',
          sorter: true
        },

        {
          title: '图片视频',
          dataIndex: 'res',
          scopedSlots: {customRender: 'res'}
        },
        {
          title: '经销商',
          dataIndex: 'memberName'
        },
        {
          title: '经销商手机',
          dataIndex: 'memberPhone'
        },
        {
          title: '奖励',
          dataIndex: 'priceReward',
          customRender(text, record) {
            return text ? (text/100 + "元") : ""
          }
        },
        {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'}
        }
      ]
    }
  },
  methods: {
    add() {
      this.$refs.afterSalesEdit.show()
    },
    edit(record) {
      this.$refs.afterSalesEdit.show(record)
    },
    remove(record) {
      let that = this
      this.$confirm({
        title: '是否删除该记录?',
        content: '该操作不可撤销',
        centered: true,
        onOk() {
          that.$delete('web/machine/brand/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },
    clickDeduction(record){
      this.$refs.afterSalesDeduction.open(record)
    },
    didDeduction(data){
      this.$post("web/machine/aftersales/deduction",data).then(suc=>{
        this.getList()
      })
    },
    getList() {
      this.$refs.table.getData()
    },
    clickImage(record){
      this.$refs.imageModal.show(JSON.parse(record.res))
    }
  }
}
</script>

<style scoped>
.res{
  width: 60px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 10px;
}
</style>
