<template>
  <a-modal v-model="visible" title="提交图片" @ok="handleOk" width="90%">
    <a-carousel arrows>
      <div
          slot="prevArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="left: 10px;zIndex: 1"
      >
        <a-icon type="left-circle" />
      </div>
      <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
        <a-icon type="right-circle" />
      </div>
      <div style="height: 80vh;width: 100%" v-for="image in imageList">
        <img style="height: 100%;width: 100%;object-fit: contain" :src="image.url || image.cover">
      </div>
    </a-carousel>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      imageList:[]
    }
  },
  methods: {
    show(list) {
      this.imageList = list
      this.visible = true
    },
    handleOk() {
      this.visible = false
    }
  }
}
</script>

<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 80%;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 35px;
  height: 35px;
  font-size: 35px;
  color: #000;
  opacity: 0.8;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #000;
}
</style>
