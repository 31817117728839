<template>
  <a-modal
      title="扣除奖励"
      :visible="visible"
      @ok="clickOk"
      @cancel="close"
  >
    <div class="container">


      <a-descriptions bordered size="small" :column="1">
        <a-descriptions-item label="产品名称">{{record.productName}}</a-descriptions-item>
        <a-descriptions-item label="经销商">{{record.memberName}}</a-descriptions-item>
        <a-descriptions-item label="奖励">{{record.priceReward/100}}元</a-descriptions-item>
<!--        <a-descriptions-item label="扣除奖励"><a-input v-model="count" suffix="元"/></a-descriptions-item>-->
      </a-descriptions>

<!--      <a-alert message="确认成交后，将计算相关用户的分佣，该操作无法撤销" type="info" show-icon style="margin-top: 10px" />-->

    </div>
  </a-modal>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {
      visible: false,
      record:{},
      count:0,
    }
  },
  methods: {
    open(record={}) {
      this.record = JSON.parse(JSON.stringify(record))
      this.count = ""
      this.visible = true
    },
    close() {
      this.visible = false
    },
    clickOk() {
      if(this.count<=0){
        return
      }
      this.visible = false
      this.$emit("ok",{
        id:this.record.id,
        price: parseInt(this.count*100)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  background: #FFF;

  .title {
    text-align: center;
    font-size: 30px;
    border-bottom: 1px solid #DDD;
  }

  .content {

  }

  .row {
    display: flex;
    border-top: 1px solid #DDD;
  }

  .btn-action {
    flex: 1;
    text-align: center;
    font-size: 30px;
  }

  .btn-action:active {
    background: #F0F0F0;
  }

  .btn-cancel {
    border-right: 1px solid #DDD;
    color: #999999;
  }
}

.visit-row{
  display: flex;
  margin-bottom: 10px;

  .user{
    flex: 1;
    padding: 10px;
    background: #F0F0F0;
    border-radius: 5px;

    .head{
      color: #0084FF;
      margin-bottom: 5px;
    }

    .address{
      opacity: 0.7;
    }
  }
}

</style>

